import Immutable from 'immutable';
import { pickBy } from 'lodash';

const CALENDLY_BASE_URL = 'https://calendly.com/d';

export const CALENDLY_PATHS = {
  payroll_sales_team_zero_state_page: 'cmhs-s88-2rv',
  payroll_sales_team: 'cmpk-6tk-zhb',
  team_app_team: 'ckqw-dmr-n9p',
};

export type QueryParamsType = Record<
  string,
  string | number | boolean | null | undefined
>;

export type CalendlyUrlBuilderCommand = {
  url?: string;
  email?: string;
  fullName?: string;
  queryParams?: QueryParamsType;
};

export type CalendlyUrlBuilderCommandProps = {
  url?: string;
  queryParams?: QueryParamsType;
  currentUser?: Immutable.Map<string, string>;
};

export const buildCalendlyUrl = ({
  url,
  type,
  email,
  fullName,
  queryParams,
}: CalendlyUrlBuilderCommand & { type: string }) => {
  const base_url = url || `${CALENDLY_BASE_URL}/${type}`;
  const { origin, pathname, searchParams } = new URL(base_url);

  const params = pickBy(
    {
      // any other params should be passed through
      ...Object.fromEntries(searchParams.entries()),
      // a1 is the param defined in Calendly to autofill the email
      a1: email,
      // a2 is the param defined in Calendly to autofill the first name
      a2: fullName,
      // improves the user experience by skipping the first step in Calendly
      // which is just a confirmation of the email and first name
      autofill: 'true',
      ...(queryParams || {}),
    },
    value => value && value !== ''
  ) as Record<string, string>;

  const result = new URL(`${origin}${pathname}`);

  Object.entries(params).forEach(([key, value]) =>
    result.searchParams.append(key, value)
  );

  return result.toString();
};

export const calendlyUrlBuilderCommand = ({
  url,
  queryParams,
  currentUser,
}: CalendlyUrlBuilderCommandProps): CalendlyUrlBuilderCommand => ({
  url,
  queryParams,
  email: currentUser?.get('email'),
  fullName: currentUser?.get('full_name'),
});

// Use Payroll Sales Team for Zero State Page Calendly URL or given `url`
export const buildPayrollSalesTeamZeroStatePageCalendlyUrl = (
  command: CalendlyUrlBuilderCommand
) =>
  buildCalendlyUrl({
    ...command,
    type: CALENDLY_PATHS.payroll_sales_team_zero_state_page,
  });

// Use Payroll Sales Team Calendly URL or given `url`
export const buildPayrollSalesTeamCalendlyUrl = (
  command: CalendlyUrlBuilderCommand
) =>
  buildCalendlyUrl({
    ...command,
    type: CALENDLY_PATHS.payroll_sales_team,
  });

// Use Team App Team Calendly URL or given `url`
export const buildTeamAppTeamCalendlyUrl = (
  command: CalendlyUrlBuilderCommand
) =>
  buildCalendlyUrl({
    ...command,
    type: CALENDLY_PATHS.team_app_team,
  });
